import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import Comments from "./Comments";
import Score from "./Score";

import { headers } from "../../config";

const Evaluation = ({ url, score }) => {
  const [currentScore, setCurrentScore] = useState(score);
  const scoreCommentsRef = useRef(null);

  const onHandleScore = ({ value, comments }) => {
    setCurrentScore(value);
    postData({ value });

    scoreCommentsRef.current.classList.add("show");
  };

  const onHandleComments = ({ comments }) => {
    postData({ value: currentScore, comments: comments });

    scoreCommentsRef.current.classList.add("hide");
  };

  const postData = ({ value, comments }) => {
    fetch(url, {
      headers: headers,
      method: "post",
      body: JSON.stringify({
        evaluation: { score: value, comments: comments },
      }),
    }).then((response) => {
      if (!response.ok) {
        alert("Erro ao avaliar esta aula.");
      }
    });
  };

  return (
    <>
      <Score score={currentScore} onHandleScore={onHandleScore} />
      <div ref={scoreCommentsRef} className="score-comments">
        <Comments onHandleComments={onHandleComments} />
      </div>
    </>
  );
};

Evaluation.propTypes = {
  url: PropTypes.string,
  score: PropTypes.number,
};

export default Evaluation;
