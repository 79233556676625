export const token = document
  .querySelector('meta[name="csrf-token"]')
  .getAttribute("content");

export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
  "X-CSRF-Token": token,
};
