const loadCollapse = () => {
  const actives = Array.from(
    document.querySelectorAll(".lecture-list .active")
  );

  actives.forEach((active) => {
    active.scrollTop = 100;
  });

  const triggers = Array.from(
    document.querySelectorAll('[data-toggle="collapse"]')
  );

  triggers.forEach((trigger) => {
    trigger.addEventListener(
      "click",
      (event) => {
        event.preventDefault();
        const element = event.target;

        const selector = element.getAttribute("data-target");
        collapse(selector, "toggle");
      },
      false
    );
  });

  const fnmap = {
    toggle: "toggle",
    show: "add",
    hide: "remove",
  };

  const collapse = (selector, cmd) => {
    const targets = Array.from(document.querySelectorAll(selector));

    targets.forEach((target) => {
      target.classList[fnmap[cmd]]("show");
    });
  };
};

document.addEventListener("load", loadCollapse);
document.addEventListener("turbolinks:load", loadCollapse);
