import React, { useState } from "react";
import PropTypes from "prop-types";

const Comments = ({ onHandleComments }) => {
  const [comments, setComments] = useState();

  return (
    <div className="score-comments-form">
      <textarea
        name="comments"
        id="comments"
        placeholder="comentário (opcional)..."
        value={comments}
        onChange={() => setComments(event.target.value)}
      ></textarea>
      <a
        onClick={() => onHandleComments({ comments })}
        className="button solid tiny dark"
      >
        Enviar avaliação
      </a>
    </div>
  );
};

Comments.propTypes = {
  handleUpdate: PropTypes.func,
};

export default Comments;
