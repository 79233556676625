import React from "react";
import PropTypes from "prop-types";

import Vimeo from "./Vimeo";

import { headers } from "../../config";

const Player = ({ videoUrl, url, lectureId, trackingKey }) => {
  const onPlay = () => {
    postData({ progress: 0, isConcluded: false });
  };

  const onProgress = ({ seconds, progress, duration }) => {
    postData({ progress: progress, isConcluded: progress >= 95 });
  };

  const onEnd = () => {
    postData({ progress: 100, isConcluded: true });
  };

  const postData = ({ progress, isConcluded }) => {
    return; // removed data over fetch

    if(url) {
      fetch(url, {
        headers: headers,
        method: "post",
        body: JSON.stringify({
          conclusion: { total: progress, is_concluded: isConcluded },
        }),
      }).then((response) => {
        if (!response.ok) {
          console.error(`Error on update lecture conclusion: ${response.status}`);
        }
      });

      if (typeof fbq === "function") {
        fbq("trackCustom", "LMSLectureConclusion", {
          key: trackingKey,
          id: `lecture-${lectureId}`,
          progress: progress,
          isConcluded: isConcluded,
        });
      }

      if (typeof gtag === "function" && typeof ga === "function") {
        ga("send", "event", "lecture", "progress", progress);
        ga("send", "event", "lecture", "isConcluded", isConcluded);
        ga("send", "event", `lecture-${lectureId}`, "progress", progress);
        ga("send", "event", `lecture-${lectureId}`, "isConcluded", isConcluded);

        if (isConcluded) {
          gtag("event", "conversion", {
            send_to: "AW-986290856/PrvUCJ6l8-EBEKi1ptYD", // [LMS] Lecture Concluded
            value: 1.0,
            currency: "BRL",
          });
        }
      }
    }
  };

  return (
    <Vimeo
      videoUrl={videoUrl}
      onPlay={onPlay}
      onProgress={onProgress}
      onEnd={onEnd}
    />
  );
};

export default Player;
