import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const Value = ({ isChecked, value, onOver, onLeave, onHandleScore }) => {
  const classeNames = [
    "icon",
    "icon-star-empty",
    isChecked ? "icon-star-full" : "",
  ];

  return (
    <a
      onMouseOver={() => onOver(value)}
      onMouseLeave={() => onLeave(value)}
      onClick={() => onHandleScore({ value })}
    >
      <span className={classeNames.join(" ")} style={{ fontSize: 32 }}></span>
    </a>
  );
};

Value.propTypes = {
  isChecked: PropTypes.bool,
  value: PropTypes.number,
  onOver: PropTypes.func,
  onLeave: PropTypes.func,
  onHandleScore: PropTypes.func,
};

const Score = ({ score, onHandleScore }) => {
  const [values, setValues] = useState([]);

  useEffect(() => {
    const onOver = (value) => toggle(value);
    const onLeave = () => toggle(score);

    const toggle = (s) => {
      setValues([]);

      for (let value = 1; value <= 5; value++) {
        const isChecked = value <= s;

        setValues((values) => [
          ...values,
          { isChecked, value, onOver, onLeave },
        ]);
      }
    };

    toggle(score);
  }, [score]);

  return (
    <div className="score">
      {values.map(({ isChecked, value, onOver, onLeave }) => (
        <Value
          key={value}
          isChecked={isChecked}
          value={value}
          onOver={onOver}
          onLeave={onLeave}
          onHandleScore={onHandleScore}
        />
      ))}
    </div>
  );
};

Score.propTypes = {
  score: PropTypes.number,
  onHandleScore: PropTypes.func,
};

export default Score;
