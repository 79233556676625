import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import VimeoPlayer from "@vimeo/player";

const Vimeo = ({ videoUrl, onPlay, onProgress, onEnd }) => {
  const tracker = {
    progress: 0,
    seconds: 0,
    duration: 0,
  };

  let lastSent = null;
  let lastUpdate = null;

  const videoProgress = (data) => {
    var currentPercent = Math.ceil(data.percent * 100);

    if (tracker.progress > currentPercent) {
      return;
    }

    tracker.progress = currentPercent;
    tracker.seconds = data.seconds;
    tracker.duration = data.duration;
    lastUpdate = Math.floor(new Date().getTime() / 1000);
  };

  const updateProgress = () => {
    if (lastSent == lastUpdate) {
      return;
    }

    lastSent = lastUpdate;

    onProgress(tracker);
  };

  useEffect(() => {
    setInterval(updateProgress, 10000);

    const player = new VimeoPlayer("player", { url: videoUrl });

    player.on("play", onPlay);
    player.on("progress", videoProgress);
    player.on("ended", onEnd);
  }, []);

  return (
    <div className="embed">
      <div id="player" />
    </div>
  );
};

Vimeo.propTypes = {
  videoUrl: PropTypes.string,
  onPlay: PropTypes.func,
  onProgress: PropTypes.func,
  onEnd: PropTypes.func,
};

export default Vimeo;
